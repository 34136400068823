<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21 17V2H6C5.20435 2 4.44129 2.31607 3.87868 2.87868C3.31607 3.44129 3 4.20435 3 5V19.5"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path
      d="M21.75 22H5.5C4.83696 22 4.20107 21.7366 3.73223 21.2678C3.26339 20.7989 3 20.163 3 19.5C3 18.837 3.26339 18.2011 3.73223 17.7322C4.20107 17.2634 4.83696 17 5.5 17H21.75"
      stroke="currentColor"
      stroke-width="1.5"
    />
    <path d="M19 17V22" stroke="currentColor" stroke-width="1.5" />
  </svg>
</template>
